import React from "react";
import "./styles/color.css";
import "./styles/font.css";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/index.css";
import "./styles/tailwind.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persister, store } from "./store";

ReactDOM.render(
   <Provider  store={store}>
   <PersistGate persistor={persister}>    
    <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
 
);
