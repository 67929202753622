import { MaterialReactTable } from "material-react-table";
import React, { useMemo, useState, useEffect } from "react";
import { Button } from "@mui/material";
const data3 = [
  { code: "E101", name: "Ajay Bhatia", salary: 500000, phone: 9815215233 },
  { code: "E102", name: "Ajit Singh", salary: 510000, phone: 9415245233 },
  { code: "E103", name: "Rameshwer Singh", salary: 550000, phone: 9912215243 },
  { code: "E104", name: "James Smith", salary: 200000, phone: 9915115131 },
  { code: "E105", name: "Kruth", salary: 400000, phone: 9015115131 },
  { code: "E106", name: "Kruth", salary: 400000, phone: 9015115131 },
  { code: "E105", name: "Kruth", salary: 400000, phone: 9015115131 },
  { code: "E105", name: "Kruth", salary: 400000, phone: 9015115131 },
  { code: "E105", name: "Kruth", salary: 400000, phone: 9015115131 },
];



const TableList2 = () => {
  const [tableData, setTableData] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  useEffect(() => {
    setTableData(data3);
  }, []);
  const handleAccept = (row) => {
    console.log("Accepted row:", row);
    alert(`Accepted: ${row.name}`);
  };
  const handleReject = (row) => {
    console.log("Rejected row:", row);
    alert(`Rejected: ${row.name}`);
  };
  const columns = useMemo(() => {
    if (tableData.length === 0) return [];
    const dynamicColumns = Object.keys(tableData[0]).map((key) => ({
      accessorKey: key,
      header: key.charAt(0).toUpperCase() + key.slice(1),
    }));
    const acceptColumn = {
      id: "accept",
      header: "Accept",
      Cell: ({ row }) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleAccept(row.original)}
        >
          {" "}
          Accept{" "}
        </Button>
      ),
    };
    const rejectColumn = {
      id: "reject",
      header: "Reject",
      Cell: ({ row }) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleReject(row.original)}
        >
          {" "}
          Reject{" "}
        </Button>
      ),
    };
    return [...dynamicColumns, acceptColumn, rejectColumn];
  }, [tableData]);
  return (
    <MaterialReactTable
      columns={columns}
      data={tableData}
      enablePagination
      enableGlobalFilter
      initialState={{ pagination: { pageSize: pageSize } }}
      muiTablePaginationProps={{
        rowsPerPageOptions: [5, 10, 20],
        onRowsPerPageChange: (e) => setPageSize(parseInt(e.target.value, 10)),
      }}
      muiTableBodyRowProps={{
        sx: {
          padding: '0px',
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          padding: '4px 8px',
        },
      }}
    />
  );
};
export default TableList2;
