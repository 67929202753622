import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Img, Text, Line } from "components";

const Sidebar = ({ className, closeDrawer }) => { // Receive closeDrawer as a prop
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState('');

    useEffect(() => {
        const currentPath = location.pathname;
        const defaultTab = menuItems.find(item => currentPath.includes(item.path))?.name || 'Dashboard';
        setSelectedTab(defaultTab);
    }, [location.pathname]);

    const handleTabClick = (path, tabName) => {
        setSelectedTab(tabName);
        navigate(path); 
        if (closeDrawer) closeDrawer(); 
    };
 
    const menuItems = [
        { name: 'Dashboard', icon: 'images/homenew.png', path: '/dashboard' },
        { name: 'Transaction Limit', icon: 'images/transaction-limit.png', path: '/transaction-limit' },
        { name: 'Transactions', icon: 'images/transaction.png', path: '/fttransactions' },
        { name: 'Beneficiaries', icon: 'images/beneficiarynew.png', path: '/beneficiary' },
        { name: 'Loan Application', icon: 'images/loan.png', path: '/loanapplication' },
        { name: 'Cards', icon: 'images/debit.png', path: '/cards' },
        { name: 'Investments', icon: 'images/investmentnew.png', path: '/investments' },
        { name: 'Requests', icon: 'images/requestnew.png', path: '/request' },
        { name: 'Settings', icon: 'images/settingnew.png', path: '/settingpageone' },
        { name: 'Support', icon: 'images/customer-service.png', path: '/support' },
        // { name: 'Add Form', icon: 'images/settingnew.png', path: '/adddynamicform' },
        // { name: 'Loading Form', icon: 'images/customer-service.png', path: '/getdynamicform' },
        // { name: 'Dynamic Form', icon: 'images/customer-service.png', path: '/dynamicform' },


         



        // { name: 'Accounts', icon: 'images/img_user.svg', path: '/accounts' },
        // { name: 'Investments', icon: 'images/img_map.svg', path: '/investments' },
        // { name: 'Credit Cards', icon: 'images/img_file.svg', path: '/creditcards' },
        // { name: 'Loans', icon: 'images/img_question.svg', path: '/loan' },
        // { name: 'Services', icon: 'images/img_service1.svg', path: '/services' },
        // { name: 'My Privileges', icon: 'images/img_lightbulb.svg', path: '/my-privileges' },
        // { name: 'Table List', icon: 'images/img_lightbulb.svg', path: '/tablelist' },
        // { name: 'Table List2', icon: 'images/img_settings_25X25.svg', path: '/tablelist1' }
    ];

    return (
        <aside className={className}>
            <div className="sidebarpadding flex flex-col gap-[50px] items-start justify-start pr-[40px] py-[3vh] h-full w-full pr-[100px]" >
                <div className="flex flex-col gap-[23px] justify-start w-full" >
                    {menuItems.map((item) => (
                        <div
                            key={item.name}
                            className="w-full"
                            onClick={() => handleTabClick(item.path, item.name)}
                        >
                            <div className="relative flex items-center cursor-pointer w-full">
                                {selectedTab === item.name && (
                                    <Line className="bg-indigo_600 h-[60px] rounded-br-[10px] rounded-tr-[10px] w-[6px] absolute left-0" />
                                )}
                                <Img
                                    src={item.icon}
                                    className="h-[25px] ml-[20px] w-[25px]"
                                    alt={item.name}
                                />
                                <Text
                                    className={`font-inter font-medium ml-[20px] text-left w-[auto] ${selectedTab === item.name ? 'text-indigo_600' : 'text-gray-600'
                                        }`}
                                    as="h5"
                                    variant="h5"
                                >
                                    {item.name}
                                </Text>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </aside>
    );
};

export default Sidebar;
