export const OPERTION_CODE = {
    REGISTRATION: "MOB_APP_REG",
   LOGIN: "VERIFY_LOGIN",
   CUST_PROFILE: "CUST_PROFILE",
   ACC_LIST: "ACC_LIST",
    WITHINBANKTRANSFER: "ACCTRF",
    GET_ALL: "GET_ALL",
    GET_DYNAMIC_JSON: "GET_DYNAMIC_JSON",
    VALIDATE_MOB:"VALIDATE_MOB",
    LOAN_APP:"LOAN_APP",
    NEFT: "NEFT",
    RTGS:"RTGS",
    IMPS:"IMPS",
    CUST_REQ: "CUST_REQ"
  };