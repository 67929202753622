// eslint-disable-next-line import/prefer-default-export
export const loginDetailsSelector = (state) => state.app.loginDetails;
export const profileDetailsSelector =  (state) => state.app.profileDetails;
export const permissionListSelector =  (state) => state.app.permissionList;
export const totalPermissionListSelector =  (state) => state.app.totalPermissions;
export const agentDetailsSelector =  (state) => state.app.agentDetails;
export const branchListSelector = (state) => state.app.branchList;
export const userListSelector = (state) => state.app.userList;
export const accountListSelector = (state) => state.app.accounts;
export const beneAccountListSelector = (state) => state.app.beneAccountList;

