import React, { useState, useCallback,useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./layout.css";
import SideBar from "./sidebar";
import TopBanner from "./topbanner";

import { authTokenSelector } from "../../store/selectors/auth.selector";
import { useSelector, useDispatch } from "react-redux";


export default function Layout({ children }) {
    const authToken = useSelector(authTokenSelector);
    const navigate = useNavigate();

    useEffect(() => {
        if (authToken === null) {
          navigate("/login");
        }
      }, [authToken]);
    return (
        <div className="app-container">
            <div className="top-banner">
                <TopBanner />
            </div>
            <div className="render-section">
                <div className="left-section">
                    <SideBar />
                </div>
                <div className="right-section">
                    {children}
                </div>
            </div>
        </div>
    );
}
