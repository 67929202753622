import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Row, Col, Avatar, Typography, Drawer } from "antd";
import { Img } from "components";
import { Header } from "antd/es/layout/layout";
import { LogoutOutlined, SettingOutlined, BellOutlined, MenuOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import './topbanner.css';
import Sidebar from './sidebar';
import { useSelector,useDispatch } from 'react-redux';
import { loginDetailsSelector } from "store/selectors/app.selector";
import RegistrationService from "../../services/registration.service";
import config from "../../../src/services/constant";
import { OPERTION_CODE } from "Constants/enum";

const { Text } = Typography;

const formatDate = (date) => {

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    const daySuffix = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    };

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${month} ${day}${daySuffix(day)} ${year} ${hours}:${minutes} ${ampm}`;
};

const TopBanner = () => {
    const dispatch = useDispatch();

    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();

    const showDrawer = () => {
        setVisible(true);
    };

    const closeDrawer = () => {
        setVisible(false);
    };

    // Get the current date and time
    const loginDetails = useSelector(loginDetailsSelector);
    const currentDateTime = formatDate(new Date());
    //   const lastlogintime = loginDetails.lastLogin;
    //   const username =loginDetails.name;

    const [loading, setLoading] = useState(true);


    const custId = useSelector((state) => state.auth.custId);
    console.log(loginDetails)
    const [error, setError] = useState(false);
    const [userdetail, setuserdetails] = useState(null); // Initially null
    const [imageSrc, setImageSrc] = useState("");

    useEffect(() => {
        const fetchProfileDetails = async () => {
            try {
                const payload = {
                    operation_code: OPERTION_CODE.CUST_PROFILE,
                    cust_id: loginDetails?.custId || "",
                };

                const response = await RegistrationService.postRequest(payload, config.USER_PROFILE);

                setuserdetails(response.data.response);
                setImageSrc(response.data.response.documentImage)
                if (response.data.response.documentImage) {
                    const imageBase64 = response.data.response.documentImage;
                    const imageSrc = `data:image/jpeg;base64,${imageBase64}`;
                    setImageSrc(imageSrc);
                }
                if (response.status === 200 && response.data) {
                    // alert("API call successful");
                } else {
                    alert(`Error in API response status: ${response.status}`);
                    setError(true);
                }
            } catch (err) {
                console.error("API call failed", err);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchProfileDetails();
    }, []);


    const onLogout = () => {
        dispatch({ type: "USER_LOGOUT" });
        navigate("/login")
    };

    return (
        <Header className="top-header">
            <Row align="middle" justify="space-between" style={{ width: '100%' }}>
                <Col xs={2} sm={0}>
                    <MenuOutlined onClick={showDrawer} style={{ fontSize: '24px', color: '#003366' }} />
                </Col>

                <Col xs={0} sm={14} style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="images/techverve.png" alt="Logo" className="logo-image" />
                    <Text
                        xs={0}
                        className="welcome-text"
                        style={{
                            marginLeft: '100px',
                            fontSize: '22px',
                            color: '#003366',
                            flexShrink: 0
                        }}
                    >
                        Welcome to Techverve Internet Banking
                    </Text>
                </Col>

                <Col xs={22} sm={10}>
                    <Row align="middle" justify="end" gutter={[16, 0]}>
                        <Col>
                            <Avatar className="icon-avatar" onClick={() => navigate("/settingpageone")}>
                                <SettingOutlined style={{ color: '#003366' }} />
                            </Avatar>
                        </Col>
                        <Col>
                            <Avatar className="icon-avatar">
                                <BellOutlined style={{ color: 'red' }} />
                            </Avatar>
                        </Col>
                        <Col>
                            {imageSrc && <Avatar
                                // src="images/img-1.JFIF"
                                src={imageSrc}
                                className="profile-icon"
                            />}
                        </Col>
                        <Col className="user-info">
                            <div>
                                <Text strong style={{ fontSize: '16px' }}>{loginDetails.name}</Text>
                                <br />
                                <Text type="secondary" style={{ fontSize: '14px' }}>
                                    {/* {loginDetails.lastLogin} */}
                                    {loginDetails.lastLogin && new Date(loginDetails.lastLogin).toString().split('GMT')[0].trim()}


                                </Text>
                            </div>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                onClick={onLogout}
                                icon={<LogoutOutlined />}
                                style={{ backgroundColor: '#003366', borderColor: '#003366' }}
                            >
                                Logout
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Drawer
                title="Menu"
                placement="left"
                closable={true}
                onClose={closeDrawer}
                visible={visible}
                bodyStyle={{ padding: 0 }}
            >
                <Sidebar closeDrawer={closeDrawer} />
            </Drawer>
        </Header>
    );
};

export default TopBanner;
