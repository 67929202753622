import {
  SET_APP_LANGUAGE,
  SET_LOGIN_DETAILS,
  SET_USER_PROFILE,
  SET_PERMISSION_LIST,
  SET_TOTAL_PERMISSION_LIST,
  SET_AGENT_DETAILS,
  SET_BRANCH_LIST ,
  SET_USER_LIST,
  SET_SERVER_KEY,
  SET_SHARED_KEY,
  SET_DEVICE_ID,
  SET_SERVICE_LOADER,
  SET_ACCOUNT_LIST,
  SET_BENE_ACCOUNT_LIST

} from "../constants";

const initialState = {
  language: "en",
  loginDetails: {},
  profileDetails: {},
  permissionList: [],
  totalPermissions: [],
  agentDetails:{},
  branchList: [],
  userList:[],
  sharedKey: "",
  serverKey: "",
  deviceId: "",
  serviceLoader: false,
  accounts:[],
  beneAccountList:[]

};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_APP_LANGUAGE:
      return { ...state, language: payload };
    case SET_LOGIN_DETAILS:
      return { ...state, loginDetails: payload };
    case SET_USER_PROFILE:
      return { ...state, profileDetails: payload };
    case SET_PERMISSION_LIST:
      return { ...state, permissionList: payload };
    case SET_TOTAL_PERMISSION_LIST:
      return { ...state, totalPermissions: payload };
      case SET_AGENT_DETAILS:
        return { ...state, agentDetails: payload };
        case SET_BRANCH_LIST:
      return { ...state, branchList: payload };
      case SET_USER_LIST:
        return { ...state, userList: payload };

        case SET_DEVICE_ID:
          return { ...state, deviceId: payload };
    
        case SET_SHARED_KEY:
          return { ...state, sharedKey: payload };
    
        case SET_SERVER_KEY:
          return { ...state, serverKey: payload };
          case SET_SERVICE_LOADER:
            return { ...state, serviceLoader: payload };
            case SET_ACCOUNT_LIST: // Handle the account list action
      return { ...state, accounts: payload };

      case SET_BENE_ACCOUNT_LIST: // Handle the account list action
      return { ...state, beneAccountList: payload };

    default:
      return state;
  }
};
