import http from "./http.service";
import config from "./constant";
import { SET_AUTH_TOKEN, SET_LOGIN_DETAILS, SET_USER_LIST, SET_ACCOUNT_LIST, SET_BENE_ACCOUNT_LIST,SET_USER_PROFILE  } from "../store/constants";
import { store } from "../store";

const postRequest = async (data,api) => {

  const { auth, app } = store.getState();

  try {
    const reqParams = {
      cust_id:app?.loginDetails?.custId,
      ...data
    };
    const response = await http.post(api, reqParams);
    if(data.operation_code=="ACC_LIST"){
      store.dispatch({ type: SET_ACCOUNT_LIST, payload: response.data.response});

    }
    else if(data.operation_code=="CUST_PROFILE"){
      store.dispatch({ type: SET_USER_PROFILE, payload: response.data.response });

    }

    else if(data.operation_code=="GET_ALL"){

      if(response.data.code=="Error"){
        store.dispatch({ type: SET_BENE_ACCOUNT_LIST, payload: [] });
      }
      else{
        store.dispatch({ type: SET_BENE_ACCOUNT_LIST, payload: response.data });
      }

    }
    return { status: response.status, data: response.data };

    dis
  } catch (err) {
    return Promise.reject(err);
  }
};

const postRegistration = async (data,api) => {


  try {
    const reqParams = {
      ...data
    };
    const response = await http.post(api, reqParams);
    return { status: response.status, data: response.data };

    dis
  } catch (err) {
    return Promise.reject(err);
  }
};

const RegistrationService = {
  postRequest,


  postRegistration
};

export default RegistrationService;
