import axios from "./intercetor";

const http = {
  get: async (url, data = {}, headers = {}) =>
    axios.get(url, {
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    }),
  delete: async (url, data = {}, headers = {}) =>
    axios.delete(url, {
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    }),
  post: async (url, data = {}, headers = {}) =>
    axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    }),
  put: async (url, data = {}, headers = {}) =>
    axios.put(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    }),

  postUpLoad: async (url, data = {}, headers = {}) =>
    axios.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),

  downloadPost: async (url, data = {}, headers = {}) =>
    axios.post(url, data, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    }),
};

export default http;
