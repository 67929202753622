export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SET_APP_LANGUAGE = "SET_APP_LANGUAGE";
export const SET_LOGIN_DETAILS = "SET_LOGIN_DETAILS";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_SERVICE_LOADER = "SET_SERVICE_LOADER";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_AUTH_REFRESH_TOKEN = "SET_AUTH_REFRESH_TOKEN";
export const SET_PERMISSION_LIST = "SET_PERMISSION_LIST";
export const SET_TOTAL_PERMISSION_LIST = "SET_TOTAL_PERMISSION_LIST";
export const SET_AGENT_DETAILS = "SET_AGENT_DETAILS";
export const SET_BRANCH_LIST ="SET_BRANCH_LIST ";
export const SET_USER_LIST ="SET_USER_LIST ";

export const SET_DEVICE_ID ="SET_DEVICE_ID ";
export const SET_SERVER_KEY = "SET_SERVER_KEY";
export const SET_SHARED_KEY ="SET_SHARED_KEY ";
export const SET_CUST_ID = 'SET_CUST_ID';

export const SET_ACCOUNT_LIST = 'SET_ACCOUNT_LIST'
export const SET_BENE_ACCOUNT_LIST = 'SET_BENE_ACCOUNT_LIST'





